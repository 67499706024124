import Vue from 'vue'
import ApiHelper from '@/helpers/ApiHelper'
import ArrayHelper from '@/helpers/ArrayHelper';
import FileUploadHelper from '@/helpers/FileUploadHelper'

export default {
    namespaced: true,
    state: {
        loading: false,
        eduCourses: [],
        eduCoursesShort: [],
        eduCoursesVersion: 'public',
        eduCourse: null,
        eduCoursesTags: [],
        eduCoursesSyncTotal: 0
    },
    mutations: {
        TOGGLE_LOADING(state, value) {
            state.loading = value;
        },
        TOGGLE_EDU_COURSE_VERSION(state, value) {
            state.eduCoursesVersion = value;
        },
        UPDATE_TAGS(state, value) {
            state.eduCoursesTags = value;
        },
        UPDATE_EDU_COURSES(state, value) {
            let newItems = [];
            _.each((_.isArray(value) ? value : [value]), o => {
                const i = _.findIndex(state.eduCourses, {id: o.id});
                if(i >= 0)
                    state.eduCourses[i] = o;
                else
                    newItems.push(o);
            })

            Vue.set(state, 'eduCourses', [...state.eduCourses, ...(_.size(newItems) > 0 ? newItems : [])]);
            // Vue.set(state, 'eduCourses', [...state.eduCourses, ...(_.isArray(value) ? value : [value])]);
        },
        UPDATE_EDU_COURSES_SHORT(state, value) {
            let newItems = [];
            _.each((_.isArray(value) ? value : [value]), o => {
                const i = _.findIndex(state.eduCoursesShort, {id: o.id});
                if(i >= 0)
                    state.eduCoursesShort[i] = o;
                else
                    newItems.push(o);
            })

            Vue.set(state, 'eduCoursesShort', [...state.eduCoursesShort, ...(_.size(newItems) > 0 ? newItems : [])]);
        },
        FILTER_EDU_COURSES(state, handler) {
            Vue.set(state, 'eduCourses', _.filter(state.eduCourses, handler));
        },
        UPDATE_EDU_COURSE(state, value) {
            state.eduCourse = value;
        },
        UPDATE_EDU_COURSE_SYNC_TOTAL(state, value) {
            state.eduCoursesSyncTotal = value;
        }
    },
    actions: {
        toggleLoading({commit}, value) {
            commit('TOGGLE_LOADING', value);
        },
        toggleEduCourseVersion({commit}, value) {
            commit('TOGGLE_EDU_COURSE_VERSION', value);
        },
        updateEduCourse({commit}, value) {
            commit('UPDATE_EDU_COURSE', value);
        },
        async fetchTags({commit}) {
            const { data } = await ApiHelper.get(`/api/digital-edu-content/tags`);
            commit('UPDATE_TAGS', data);
        },
        async list(context, data) {
            return ApiHelper.get(`/api/digital-edu-content`, {
                ...data,
                filter: JSON.stringify(data.filter)
            });
        },
        async fetchFast({commit}, params = {}) {
            if (params.filter){
                params.filter = JSON.stringify(params.filter);
            }
            const { data } = await ApiHelper.get(`/api/digital-edu-content/short-list`, {...params}),
                items = _.get(data, 'items', []);
            commit('UPDATE_EDU_COURSES_SHORT', items);
        },
        async fetch({commit, dispatch}, filter = [], items = []) {
            if(_.size(items) <= 0)
            {
                const { data, syncTotal } = await dispatch('list', filter);
                    items = _.get(data, 'items', []);
                
                commit('UPDATE_EDU_COURSE_SYNC_TOTAL', _.isNumber(syncTotal) ? syncTotal : 0);
            }
            commit('UPDATE_EDU_COURSES', items);
        },
        async create(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/create`, data);
        },
        async update(context, data) {
            return ApiHelper.put(`/api/digital-edu-content/update`, data, {
                externalId: _.get(data, 'externalId', 0),
                version: _.get(data, 'version', '')
            });
        },
        async remove({commit}, id) {
            const response = await ApiHelper.delete(`/api/digital-edu-content/delete`, {id});
            if(_.get(response, 'success', false) === true)
            {
                commit('UPDATE_EDU_COURSE_SYNC_TOTAL', _.isNumber(response.syncTotal) ? response.syncTotal : 0);
                commit('FILTER_EDU_COURSES', (o) => {
                    return o.id !== id;
                });
            }

            return response;
        },
        async save({dispatch}, data) {
            return dispatch(_.has(data, 'id') ? 'update' : 'create', data);
        },
        async uploadFile(context, data) {
            return FileUploadHelper.upload({
				url: '/api/site/upload-file',
				file: data.file,
                payload: _.omit(data, ['file'])
			})
        },
        async uploadData(context, data) {
            return FileUploadHelper.upload({
				url: '/api/digital-edu-content/upload-data',
				file: data.file,
                query: data.query
			})
        },
        async downloadData(context, data) {
            return ApiHelper.get(`/api/digital-edu-content/download-data`, data);
        },
        async merge(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/merge`, data);
        },
        async mergeUpdate(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/merge-update`, data);
        },
        async sync(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/sync`, data, {
                externalId: _.get(data, 'externalId', 0)
            });
        },
        async syncAll() {
            return ApiHelper.post(`/api/digital-edu-content/sync-all`);
        },
        async syncTable() {
            return ApiHelper.get(`/api/digital-edu-content/sync-table`);
        },
        async import(context, data) {
            return FileUploadHelper.upload({
				url: '/api/digital-edu-content/import',
				file: data.file,
                payload: _.omit(data, ['file', 'onProgress']),
                ...(_.has(data, 'onProgress') ? {
                    onProgress: data.onProgress
                } : {})
			})
        },
        async copyVersion(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/copy-version`, data);
        },
        async view(context, data) {
            return ApiHelper.get(`/api/digital-edu-content/view`, data);
        },
        async resource(context, data) {
            return ApiHelper.get(`/api/digital-edu-content/resource`, data);
        },
        async buildApp(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/build-app`, data);
        },
        async buildApps(context, data) {
            return ApiHelper.post(`/api/digital-edu-content/build-apps`, {options: data});
        },
        async buildAppsCheck() {
            return ApiHelper.get(`/api/digital-edu-content/build-apps-check`);
        },
        async getSSOAuthUrl(context, page_to_return = window.location.pathname) {
            return ApiHelper.get(`/api/sso/get-auth-url`, { page_to_return });
        },
        async ssoLogin (context, data) {
            return ApiHelper.post(`/api/sso/login`, data);
        },
        async statsCreate ({rootState}, payload) {
            return ApiHelper.post(`/api/stats/create`, {
                access_token: rootState.user?.access_token,
                type: payload.type,
                externalId: payload.externalId || ''
            });
        },
        async ssoGetProfile (context, payload) {
            return ApiHelper.post(`/api/sso/get-profile`, { access_token: payload.access_token });
        },
        async links (context, query) {
            return ApiHelper.get(`/api/digital-edu-content/links`, query);
        },
        async linksWord (context, query) {
            return ApiHelper.get(`/api/digital-edu-content/links-word`, query);
        }
    },
    getters: {
        eduCoursesOfVersion(state) {
            return _.filter(state.eduCourses, {version: state.eduCoursesVersion});
        },
        eduCoursesTree(state, getters) {
            const items = _.map(_.sortBy(getters.eduCoursesOfVersion, ['courseTypeId', 'courseName', 'lft']), o => {
                o['noChildren'] = o.courseTypeId === 3;
                return o;
            });
            return ArrayHelper.toTree(items, 'id', 'parent_id', 'children');
        },
        eduCourseModel(state) {
            if(_.isNumber(state.eduCourse) || _.isString(state.eduCourse))
                return _.find(state.eduCourses, {id: state.eduCourse});
            return state.eduCourse;
        },
        eduCourseParentModel(state, getters) {
            if(!_.isNil(getters.eduCourseModel))
            {
                const externalParentId = _.has(getters.eduCourseModel, 'data') ? getters.eduCourseModel.data.externalParentId : getters.eduCourseModel.externalParentId;
                return _.find(getters.eduCoursesOfVersion, {externalId: externalParentId});
            }
            return null;
        },
        eduCoursesTagsOfGroup: state => group_id => {
            return _.filter(state.eduCoursesTags, {parent_id: group_id});
        },
        eduCourseModelVersionList() {
            return [{
                text: 'Публичный',
                value: 'public'
            },{
                text: 'Неопубликованный',
                value: 'unpublished'
            },{
                text: 'Для министерства',
                value: 'ministry'
            }];
        }
    }
}